<template>
    <div class="autnft-formStepsIndicator container xl-auto font-bold">
        <div class="autnft-formStep ">
            <div class="autnft-formStepIconContainer">
                <img src="/assets/icons/hexagon-on.svg" alt="" class="autnft-formStepIcon autnft-formStepIcon--active">
            </div>
            EMAIL VALIDATION
        </div>
        <div class="autnft-formStepDivider"></div>
        <div class="autnft-formStep autnft--white__opacity30">
            <div class="autnft-formStepIconContainer">
                <img src="/assets/icons/hexagon-off.svg" alt="" class="autnft-formStepIcon autnft-formStepIcon--inactive">
            </div>
            DOMAIN DATA
        </div>
        <div class="autnft-formStepDivider"></div>
        <div class="autnft-formStep autnft--white__opacity30">
            <div class="autnft-formStepIconContainer">
                <img src="/assets/icons/hexagon-off.svg" alt="" class="autnft-formStepIcon autnft-formStepIcon--inactive">
            </div>
            DOMAIN VERIFICATION
        </div>
        <div class="autnft-formStepDivider"></div>
        <div class="autnft-formStep autnft--white__opacity30">
            <div class="autnft-formStepIconContainer">
                <img src="/assets/icons/hexagon-off.svg" alt="" class="autnft-formStepIcon autnft-formStepIcon--inactive">
            </div>
            FINALIZATION
        </div>
    </div>

</template>

<script>
export default {
  name: 'FormStepsIndicator'
}
</script>

<style scoped>
.autnft-formStepsIndicator {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
}

.autnft-formStepDivider {
    background: -webkit-linear-gradient(45deg,#87d4ff 11%,#be7fff 94%);
    width: 100px;
    height: 2px;
    border-radius: 25px;
}

.autnft-formStepIcon {
    margin-right: 10px;
}

.autnft-formStepIcon--active {
    width: 30px;
    height: 30px;
}

.autnft-formStepIcon--inactive {
    width: 25px;
    height: 25px;
}

.autnft-formStep {
    display: flex;
    align-items: center;
}
</style>
