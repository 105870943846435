<template>
   <div class="autnft-formStepEmailValidationForm autnft--white mt-3">
     <div class="autnft-formStepEmailValidationNFTPrice__title  autnft-typography__title--smaller text-left font-semibold ">
       Begin to register your AutNFT Certification
       </div>
     <div class="autnft-formStepEmailValidationNFTPrice__subtitle autnft-typography__paragraph text-left mb-3 italic ">
       As the first step, we have to verify your email with a simple code confirmation that will be sent to your email. Remember to check also your SPAM Folder.
       </div>
     <div class="autnft-formStepEmailValidationForm__box autnft-typography__paragraph autnft--white__opacity10BG p-10 mr-10 br-10">
       <div class="autnft-formStepEmailValidationForm__email">
         <input type="text" placeholder="Your primary email address" class="autnft-formStepEmailValidationForm__emailInput pl-5 font-bold autnft--blue">
         <button class="autnft-formStepEmailValidationForm__emailButton text-right autnft--white font-semibold autnft-typography__subtitle--smaller">
            Send code to Email
         </button>
       </div>
       <div class="autnft-formStepEmailValidationForm__verificationCode m-5">
         <input type="number" placeholder="Your verification code" class="autnft-formStepEmailValidationForm__verificationCodeInput pl-5 font-bold autnft--blue">
         <div class="autnft-formStepEmailValidationForm__verificationCodeButtonWrapper autnft--lightBlueGradientBG">
          <button class="autnft-formStepEmailValidationForm__verificationCodeButton font-semibold font-semibold autnft-typography__subtitle--smaller">
            Verify and continue
          </button>
         </div>
         
       </div>
     </div>
   </div>
</template>


<script>
export default {
  name: 'FormStepsValidationForm'
}
</script>

<style scoped>
.autnft-formStepEmailValidationForm__box {
  border-radius: 10px;
}

.autnft-formStepEmailValidationForm__email {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  background-color: white;
  color: black;
  height: 45px;
  border-radius: 25px;
}

.autnft-formStepEmailValidationForm__email input[type="text"] {
  border-radius: 25px 0 0px 25px ;
  width: 100%;
}

.autnft-formStepEmailValidationForm__emailButton {
  width: 100%;
  max-width: 175px;
  padding-right: 15px;
  border-radius: 25px;
  background: -webkit-linear-gradient(45deg, #fbaf3b 20%, #fb6e3b);
  margin-right: -2px;
}


.autnft-formStepEmailValidationForm__verificationCode {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

.autnft-formStepEmailValidationForm__verificationCode input[type="number"]{
  border-radius: 25px;
  width: 50%;
  min-width: 200px;
  margin-right: 20px;
}

.autnft-formStepEmailValidationForm__verificationCodeButtonWrapper {
  padding: 3px;
  border-radius: 25px;
  min-width: 175px;
}

.autnft-formStepEmailValidationForm__verificationCodeButton {
  background-color: #442e92;
  height: 100%;
  width: 100%;
  border-radius: 25px;
  padding: 5px 10px
}

input::placeholder {
  font-style: italic;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
