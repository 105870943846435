<template>
   <FormStepEmailValidation></FormStepEmailValidation>
</template>

<script>
import FormStepEmailValidation from  '@/components/GetNFT/FormSteps/FormStepEmailValidation/FormStepEmailValidation.vue'

export default {
  name: 'FormSteps',
  components: {
    FormStepEmailValidation
  }
}
</script>

<style scoped>

</style>
