<template>
    <div class="autnft-formStepEmailValidation container xl-auto">
      <div class="autnft-formStepEmailValidation__title autnft--white autnft-typography__title text-center font-bold mt-10">Get now your auth certificate <span class="autnft--lightBlue">NFT</span> of your domain!</div>
      <div class="FormStepEmailValidationFeatures">
        <FormStepEmailValidationFeatures></FormStepEmailValidationFeatures>
        </div>
      <div class="FormStepEmailValidationCards">
        <FormStepEmailValidationCards></FormStepEmailValidationCards>
      </div>
      <div class="FormStepEmailValidationForm"> 
        <FormStepEmailValidationForm></FormStepEmailValidationForm>
      </div>
      <div class="FormStepEmailValidationNFTPrice"> 
        <FormStepEmailValidationNFTPrice></FormStepEmailValidationNFTPrice>
        </div>
      
      
     
     
    </div>
</template>

<script>

import FormStepEmailValidationFeatures from '@/components/GetNFT/FormSteps/FormStepEmailValidation/FormStepEmailValidationFeatures.vue'
import FormStepEmailValidationCards from '@/components/GetNFT/FormSteps/FormStepEmailValidation/FormStepEmailValidationCards.vue'
import FormStepEmailValidationForm from '@/components/GetNFT/FormSteps/FormStepEmailValidation/FormStepEmailValidationForm.vue'
import FormStepEmailValidationNFTPrice from '@/components/GetNFT/FormSteps/FormStepEmailValidation/FormStepEmailValidationNFTPrice.vue'


export default {
  name: 'FormStepEmailValidation',
  components: {
    FormStepEmailValidationFeatures,
    FormStepEmailValidationCards,
    FormStepEmailValidationForm,
    FormStepEmailValidationNFTPrice
  }
}
</script>

<style scoped>
.autnft-formStepEmailValidation {
  display: grid;
  grid-template-columns: 6fr 4fr;

}

.autnft-formStepEmailValidation__title {
  grid-row: 1 ;
  grid-column: 1 / span 2;
}

.FormStepEmailValidationFeatures {
  grid-row: 2;
  grid-column: 1;
}

.FormStepEmailValidationCards {
  grid-row: 2;
  grid-column: 2;
}

.FormStepEmailValidationForm {
  grid-row: 3;
  grid-column: 1;
}

.FormStepEmailValidationNFTPrice {
  grid-row: 3;
  grid-column: 2;
  display: flex;
  align-items: flex-end;
}
</style>