<template>
   <div>
     <div class="autnft-formStepEmailValidationCards__animatedCard"></div>
     <div class="autnft-formStepEmailValidationCards__qrCard">
        <div class="autnft-formStepEmailValidationCards__qrCardImage"></div>
        <div>Your Certified Card</div>
     </div>

   </div>
</template>

<script>
export default {
  name: 'FormStepsValidationCards'
}
</script>

<style scoped>

</style>
