<template>
   <div class="autnft-formStepEmailValidationNFTPrice autnft--white">
     <div class="autnft-formStepEmailValidationNFTPrice__title  autnft-typography__title--smaller text-right font-semibold ">Current auth Certificate NFT Price:</div>
     <div class="autnft-formStepEmailValidationNFTPrice__subtitle autnft-typography__paragraph--smaller text-right mb-3 italic ">The cryptocurrency amount is subject to change based on market variations.</div>
     <div class="autnft-formStepEmailValidationNFTPrice__cryptoBox autnft--white__opacity10BG autnft-formStepEmailValidationNFTPrice__bitcoin">
       <img src="/assets/icons/icon_btc.svg" alt="bitcoin icon" class="autnft-formStepEmailValidationNFTPrice__cryptoImage">
       <div class="font-bold mt-3 mb-1">0.0016 BTC</div>
       <div class="font-bold autnft--grey">100,00$</div>
     </div>
     <div class="autnft-formStepEmailValidationNFTPrice__cryptoBoxDivider autnft-typography__subtitle font-bold">or</div>
     <div class="autnft-formStepEmailValidationNFTPrice__cryptoBox autnft--white__opacity10BG autnft-formStepEmailValidationNFTPrice__ethereum">
       <img src="/assets/icons/icon_eth.svg" alt="ethereum icon" class="autnft-formStepEmailValidationNFTPrice__cryptoImage">
       <div class="font-bold mt-3 mb-1">0.0026 ETH</div>
       <div class="font-bold autnft--grey">100,00$</div>
     </div>
     <div class="autnft-formStepEmailValidationNFTPrice__cryptoBoxDivider autnft-typography__subtitle font-bold">or</div>
     <div class="autnft-formStepEmailValidationNFTPrice__cryptoBox autnft--white__opacity10BG  autnft-formStepEmailValidationNFTPrice__usdt">
       <img src="/assets/icons/icon_usdt.svg" alt="usdt icon" class="autnft-formStepEmailValidationNFTPrice__cryptoImage">
       <div class="font-bold mt-3 mb-1">99,98 USDT</div>
       <div class="font-bold autnft--grey">100,00$</div>
     </div>
   </div>
</template>


<script>
export default {
  name: 'FormStepsValidationNFTPrice'
}
</script>

<style scoped>
.autnft-formStepEmailValidationNFTPrice {
  display: grid;
  grid-template-columns: 130px 40px 130px 40px 130px;
  width: 100%;

}

.autnft-formStepEmailValidationNFTPrice__title {
  grid-row: 1;
  grid-column: 1 / span 5;
}

.autnft-formStepEmailValidationNFTPrice__subtitle {
  grid-row: 2;
  grid-column: 1 / span 5;
} 

.autnft-formStepEmailValidationNFTPrice__bitcoin {
  grid-row: 3;
  grid-column: 1;
}

.autnft-formStepEmailValidationNFTPrice__ethereum {
  grid-row: 3;
  grid-column: 3;
}

.autnft-formStepEmailValidationNFTPrice__usdt {
  grid-row: 3;
  grid-column: 5;
}

.autnft-formStepEmailValidationNFTPrice__cryptoBox  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0;
}

.autnft-formStepEmailValidationNFTPrice__cryptoImage {
  width: 80px;
}

.autnft-formStepEmailValidationNFTPrice__cryptoBoxDivider {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
