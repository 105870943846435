<template>
  <div class="autnft-getnft">
    <FormStepIndicator></FormStepIndicator>
    <FormSteps></FormSteps>
  </div>
</template>

<script>
import FormStepIndicator from '@/components/GetNFT/FormSteps/FormStepsIndicator.vue'
import FormSteps from '@/components/GetNFT/FormSteps.vue'

export default {
  name: 'GetNFT',
  components: {
    FormStepIndicator,
    FormSteps
  }
}
</script>


<style scoped>

</style>
