<template>
    <div class="autnft-formStepEmailValidationFeatures">
      <div class="autnft--white autnft-typography__title--smaller text-left font-semibold mb-3">What will you get?</div>
      <div class="autnft--white font-semibold autnft-typography__paragraph leading-8 italic ">
        - A unique <span>NFT</span> sent to your ETH Wallet <br>
        - An animated card of the <span>NFT</span> with unique ID that includes your domain name and logo <br>
        - A QR Card/Tag connected to your certificate, personalized with your logo and domain name <br>
        - Your <span>NFT</span> is collectible: Buy or sell them with other people! <br>
        - HTML code of your QR Card to be embedded in your website, to show your Auth Certificate <span>NFT</span> <br>
        - Registration of 10 pages of your website uin out autnft.com/archive to create a proof of time of your ideas <br>
      </div>
      <div class="my-5">
        <a href="" class=" autnft--white autnft--button autnft-typography__subtitle--smaller font-semibold autnft--greenGradientBG ">
           Discover how it will look!
         </a>
      </div>
      <div class="autnft-typography__paragraph--smaller autnft--white italic ">
        Once your request is verified, a web page will be sent to your email <br>
        within 48 hours where you will find what you have purchased.
      </div>
    </div>
</template>


<script>
export default {
  name: 'FormStepsValidatioFeatures'
}
</script>

<style scoped>

</style>
